import React from 'react'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import { Box } from 'components/primitives'
import Link from 'components/core/Link'
import Image from 'components/core/Image'
import image from 'images/main.jpg'

export default props => {
  return (
    <Layout py={3}>
      <Head />
      <Box maxWidth={640} mx='auto'>
        <Link px={2} variant='none' color='initial' to='/products/el-tor01'>
          <Image mb={4} src={image} />
        </Link>
      </Box>
    </Layout>
  )
}
